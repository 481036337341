// Import UIkit
import '/src/css/app.scss';

import UIkit from 'uikit';
import Icons from 'uikit/dist/js/uikit-icons';

// loads the Icon plugin
UIkit.use(Icons);

// components can be called from the imported UIkit reference
// UIkit.notification('Hello world.');
// /Import UIkit

// Import Vue
import Vue from 'vue';
// import Vue from 'vue/dist/vue.esm.js';

import VeeValidate from 'vee-validate';

Vue.use(VeeValidate, {
    events: 'input|blur'
});

// Contact form VeeValidate validation
if (document.querySelector('#contact-form')) {
    new Vue({
        el: '#contact-form',
        delimiters: ['${', '}'],

        data: {
            submitClicked: false,
            reCaptchaValid: false
        },

        computed: {
            formValid () {
                // loop over all contents of the fields object and check if they exist and valid.
                return Object.keys(this.fields).every(field => {
                    return this.fields[field] && this.fields[field].valid;
                })
            }
        },

        methods: {
            // Check if submit button has been clicked
            // Based on: https://jsfiddle.net/67p51oek/
            clickSubmit () {
                this.submitClicked = true;
                console.log(this.submitClicked);
            },

            clickReCaptcha (response)  {
                this.reCaptchaValid = true;
                console.log(this.reCaptchaValid);
            }
        }
    });
}

// Publish photo
// Vue.component('publish-photo', {
//     data () {
//         return {
//             submitClicked: false,
//         }
//     },

//     computed: {
//         formValid () {
//             // loop over all contents of the fields object and check if they exist and valid.
//             return Object.keys(this.fields).every(field => {
//             return this.fields[field] && this.fields[field].valid;
//             })
//         },
//     },

//     // Toggle enabling/disabling of submit button
//     methods: {
//         // Check if submit button has been clicked
//         // Based on: https://jsfiddle.net/67p51oek/
//         clickSubmit () {
//             this.submitClicked = true;
//         }
//     }
// });
// /Publish photo

// Edit photo
// Vue.component('edit-photo', {
//     data  () {
//         return {
//             actionVal: 'save-entry',
//             submitClicked: false,
//         }
//     },

//     computed: {
//         formValid () {
//             // loop over all contents of the fields object and check if they exist and valid.
//             return Object.keys(this.fields).every(field => {
//                 return this.fields[field] && this.fields[field].valid;
//             })
//         },
//     },
//     methods: {
//         clickSubmit () {
//             this.submitClicked = true;
//         },

//         onSubmit () {
//             this.$nextTick(function () {
//                 this.$refs.form.submit();
//             })
//         }
//     }
// });
// /Edit photo

// new Vue({
//     el: '#app',
//     delimiters: ['${', '}'],
// });

// Lazysizes
// import 'lazysizes';
